import { ExternalConnection, Account, DuplicateFlows, DuplicateFlow } from '../../../../types'
import { useEffect, useState } from 'react'
import {
    getTemplates,
    getTemplatesWithSteps,
    templateToFlow,
    getCompletedJobs,
    createReport,
    makeAllOption,
} from './logic'
import {
    Box,
    Tabs,
    Tab,
    TextField,
    Button,
    Checkbox,
    FormGroup,
    Radio,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { v4 as uuidv4 } from 'uuid'
import { GetAllProceduresEndpoint } from '../../../../../../server/http/procedures/getAll.no-deps'
import { GetAllEndpoint as UnitTypesOutput } from '../../../../../../server/http/unitTypes/getAll.no-deps'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { EyeIcon, CopiesIcon, StopIcon, DeleteIcon, ChevronDown, ChevronUp } from '../../icons'
import { AddWorkflowOutput, AddEndpoint } from '../../../../../../server/http/workflows/add.no-deps'
import { DateString, TEMPORARY_STUB } from '../../../../../../shared/lib/typeHelpers.no-deps'
import { template } from '@babel/core'
import { SyncFlowWorkflow } from '../../../../../../shared/domain/workflows'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { match } from 'assert'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

type AppState = {
    account: ExternalConnection
    instances: Account[]
    duplicateFlows: DuplicateFlows
}
export function App(props: AppState) {
    const [jobTemplates, setJobTemplates] = useState<any[] | null>(null)
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
    const [sortColumn, setSortColumn] = useState<
        'lastAuthoredAt' | 'name' | 'publicVersion' | 'owner' | 'migrated' | 'archived' | null
    >(null)
    const [search, setSearch] = useState<string>('')
    const [selectedTemplates, setSelectedTemplates] = useState<any[]>([])
    const [page, setPage] = useState<'list' | 'update'>('list')
    useEffect(() => {
        getTemplates(props.account).then((templates) => {
            setJobTemplates(templates.success.templates)
        })
    }, [])
    if (!jobTemplates)
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )


if (page === 'update')
    return <Update flows={props.duplicateFlows} account={props.account} instances={props.instances} selectedTemplates={selectedTemplates}/>

    const handleSort = (column: 'lastAuthoredAt' | 'name' | 'publicVersion' | 'owner' | 'migrated' | 'archived' | null) => {
        const isAsc = sortColumn === column && sortDirection === 'asc'
        setSortDirection(isAsc ? 'desc' : 'asc')
        setSortColumn(column)
    }
    
    const filteredJobTemplates = jobTemplates
        .map((template) => {
            return {
                name: template.title,
                id: template.id,
                lastAuthoredAt: new Date(template.lastAuthoredAt * 1000),
                publicVersion: template.publicVersion,
                owner: template.lastAuthor?.name || '',
                archived: template.archivedAt > 0
            }
        })
        .filter((template) => search.length === 0 || template.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
        .sort((a, b) => {
            if (!sortColumn) return 0
            if (sortColumn === 'migrated') {
                let a_total = props.duplicateFlows[a.id]?.length || 0
                let b_total = props.duplicateFlows[b.id]?.length || 0
                if (sortDirection === 'asc') {
                    if (a_total < b_total) return -1
                    else if (a_total > b_total) return 1
                }
                if (sortDirection === 'desc') {
                    if (a_total > b_total) return -1
                    else if (a_total < b_total) return 1
                }
                return 0
            }

            if (sortColumn === null || a[sortColumn] === null || b[sortColumn] === null) return 0
            let first = typeof a[sortColumn] === 'string' ? (a[sortColumn] as string).toLowerCase() : a[sortColumn]
            let second = typeof b[sortColumn] === 'string' ? (b[sortColumn] as string).toLowerCase() : b[sortColumn]
            if (first === null || second === null) return 0
            if (sortDirection === 'asc') {
                if (first < second) return -1
                else if (first > second) return 1
            }
            if (sortDirection === 'desc') {
                if (first > second) return -1
                else if (first < second) return 1
            }
            return 0
        })

    const allSelectedChecked =
        filteredJobTemplates
            .map((template) => {
                return selectedTemplates.find((st) => st.id === template.id) !== undefined
            })
            .filter((found) => found === false).length === 0

    return (
        <div className="ViewFlows">
            <div className="row">
                <div className="column">
                    <div className="breadcrumb">
                        <sub>Parsable Migration</sub>
                    </div>
                </div>
                <div className="column">
                    <TextField
                        className="search"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder="Search"
                    />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={allSelectedChecked}
                                    onChange={(e: any) => {
                                        if (e.target.checked) setSelectedTemplates([...filteredJobTemplates])
                                        else setSelectedTemplates([])
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortColumn === 'name'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={sortColumn === 'lastAuthoredAt'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('lastAuthoredAt')}
                                >
                                    Last Updated
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortColumn === 'publicVersion'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('publicVersion')}
                                >
                                    Version
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortColumn === 'owner'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('owner')}
                                >
                                    Last Author
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortColumn === 'archived'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('archived')}
                                >
                                    Archived
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortColumn === 'migrated'}
                                    direction={sortDirection}
                                    onClick={() => handleSort('migrated')}
                                >
                                    Migrated?
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredJobTemplates.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="center">
                                    <Checkbox
                                        checked={selectedTemplates.find((st) => st.id === row.id) !== undefined}
                                        onChange={(e: any) => {
                                            if (e.target.checked) {
                                                selectedTemplates.push(row)
                                                setSelectedTemplates([...selectedTemplates])
                                            } else {
                                                setSelectedTemplates([
                                                    ...selectedTemplates.filter((tem) => tem.id !== row.id),
                                                ])
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">
                                    <div className="row-text-crop">{row.lastAuthoredAt.toDateString()}</div>
                                </TableCell>
                                <TableCell align="center">{row.publicVersion}</TableCell>
                                <TableCell align="right">
                                    <div className="row-text-crop">{row.owner}</div>
                                </TableCell>
                                <TableCell align="right">
                                    {row.archived ? <>🗑</> : <></>}
                                </TableCell>
                                <TableCell align="center">
                                    {props.duplicateFlows[row.id] && props.duplicateFlows[row.id].length > 0
                                        ? props.duplicateFlows[row.id]
                                              .map((rw) => {
                                                  const customer = props.instances.find(
                                                      (instance) => instance.customerId === rw.customerId
                                                  )
                                                  if (!customer) return undefined
                                                  return customer.customerName
                                              })
                                              .filter((customer) => customer !== undefined)
                                              .filter(function (item, pos, self) {
                                                  return self.indexOf(item) == pos
                                              })
                                              .map((customer) => {
                                                  return <Chip label={customer} />
                                              })
                                        : ''}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedTemplates.length > 0 ? (
                <div className="bottom-action-bar">
                    <div className="info">
                        {`${selectedTemplates.length} Templates Selected`}
                        <div className="line"></div>
                        <a onClick={() => setSelectedTemplates([])}>
                            <DeleteIcon /> Clear Selection
                        </a>
                    </div>
                    <Button variant="contained" onClick={() => setPage('update')}>
                        {`Migrate ${selectedTemplates.length} Templates`}
                    </Button>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}
type UpdateState = {
    account: ExternalConnection,
    selectedTemplates: any[],
    instances: Account[],
    flows: DuplicateFlows
}
type MappedTemplates = {
    valid: boolean
    template: any
    instance: Account
    mappings: MigratedTemplate
    complete?: boolean
}
function Update(props: UpdateState) {
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
    const [page, setPage] = useState<'list' | 'update' | 'migrate'>('list')
    const [mappedTemplates, setMappedTemplates] = useState<MappedTemplates[]>([])
    const [templatesWithSteps, setTemplatesWithSteps] = useState<null | any[]>(null)
    let [redraw, setRedraw]= useState(0)

    useEffect(() => {
        getTemplatesWithSteps(
            props.account,
            props.selectedTemplates.map((template) => template.id)
        ).then((templates) => {
            setTemplatesWithSteps(templates.success)
        })
    }, [props.selectedTemplates])

    if (!templatesWithSteps)
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )

    if (!selectedAccount || page === 'list')
        return (
            <div className="ViewFlows">
                <h2>Select Instance</h2>
                <div className="ProcedureSection">
                    <FormGroup>
                        {props.instances.map((act) => {
                            return (
                                <FormControlLabel
                                    onChange={(event) => {
                                        setSelectedAccount(act)
                                    }}
                                    checked={act.customerId === selectedAccount?.customerId}
                                    control={<Radio />}
                                    label={act.customerName}
                                />
                            )
                        })}
                    </FormGroup>
                </div>
                <Button
                    onClick={() => {
                        setPage('update')
                    }}
                    variant="contained"
                    disabled={!selectedAccount}
                    className={'big-button' + (!selectedAccount ? ' disabled' : '')}
                >
                    Next
                </Button>
            </div>
        )
    if (page === 'migrate') {
        return <div className="ProcedureSection success">
        <h2>Migrating</h2> {
            mappedTemplates.filter((mt) => mt && mt.template).map((mt, index) => {
                let existingWorkflow;
                if (props.flows[mt.template.id])
                    existingWorkflow = props.flows[mt.template.id].filter(template => template.customerId === mt.instance.customerId && template.workflow.procedure?.id === mt.mappings.procedure).map(template => template.workflow.id).pop()
                if (mt.complete || [...mappedTemplates].splice(0, index).filter(mt => !mt.complete).length === 0) {
                    return <CopyOver markComplete={() => {mt.complete = true; setRedraw(Math.random())}} template={mt} connection={props.account} existingWorkflow={existingWorkflow}/>
                }
                return <li>
                    <p>
                        {mt.mappings.name}
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography
                                variant="body2"
                                sx={{ color: 'text.secondary' }}
                            >{`Waiting to start`}</Typography>
                        </Box>
                    </p>
                </li>
            })
        }
        </div>
    }

    const buttonDisabled = mappedTemplates.filter((mt) => mt.valid).length !== props.selectedTemplates.length
    return (
        <div className="ViewFlows">
            <h2>Migrating to {selectedAccount.customerName}</h2>
            {props.selectedTemplates.map((template) => {
                let tem = mappedTemplates.find(mt => mt.template.id === template.id);
                if (!tem)
                    mappedTemplates.push({
                        valid: false,
                        template: templatesWithSteps[template.id].template,
                        instance: selectedAccount,
                        mappings: {
                            name: template.name,
                            procedure: null,
                            subjectUnit: null,
                            procedureName: null,
                            migrateSince: null,
                            taskTraining: 'task'
                        },
                    })
                return (
                    <Migrate
                        account={selectedAccount}
                        template={template}
                        //@ts-ignore
                        mappings={mappedTemplates.find(mt => mt.template.id === template.id).mappings}
                        setMappings={(valid, mappings) => {
                            let tem = mappedTemplates.find(mt => mt.template.id === template.id);
                            if (!tem)
                                return;
                            tem.mappings = mappings; 
                            tem.valid = valid; 
                            setMappedTemplates(JSON.parse(JSON.stringify(mappedTemplates)))
                        }}
                        applyToAll={
                            (mappings) => {
                                setMappedTemplates(mappedTemplates.map(mt => {
                                    mt.mappings = {
                                        name: mt.mappings.name,
                                        procedure: mappings.procedure,
                                        procedureName: mappings.procedureName,
                                        subjectUnit: mappings.subjectUnit,
                                        taskTraining: mappings.taskTraining,
                                        migrateSince: mappings.migrateSince
                                    } as MigratedTemplate
                                    mt.valid = checkMigrationValid(mt.mappings)
                                    return mt
                                }))
                            }
                        }
                    />
                )
            })}
            <Button
                onClick={() => {
                    setPage('migrate')
                }}
                variant="contained"
                disabled={buttonDisabled}
                className={'big-button' + (buttonDisabled ? ' disabled' : '')}
            >
                Next
            </Button>
        </div>
    )
}
function checkMigrationValid(mappings: MigratedTemplate) {
    return  mappings.name.length > 0 &&
    mappings.procedure !== null &&
    (mappings.procedure > 0 || (!!mappings.procedureName && mappings.procedureName.length > 0))
}
type MigrateState = {
    account: Account
    template: any
    mappings: MigratedTemplate
    setMappings: (valid: boolean, mappings: MigratedTemplate) => void
    applyToAll: (mappings: MigratedTemplate) => void
}
type MigratedTemplate = {
    name: string
    procedure: null | number
    subjectUnit: null | number
    procedureName: null | string
    migrateSince: null | Date
    taskTraining: 'task' | 'training'
}
export function Migrate(props: MigrateState) {
    const [procedures, setProcedures] = useState({
        status: 'loading' as 'loading' | 'fail' | 'success',
        procedures: [] as GetAllProceduresEndpoint['Output']['procedures'],
    })
    const [unitTypes, setUnitTypes] = useState({
        status: 'loading' as 'loading' | 'fail' | 'success',
        unitTypes: [] as UnitTypesOutput['Output']['unitTypes'],
    })

    useEffect(() => {
        fetch('/getProcedures?customerId=' + props.account.customerId)
            .then((response) => response.text())
            .then((data) => setProcedures(JSON.parse(data)))
        fetch('/getUnitTypes?customerId=' + props.account.customerId)
            .then((response) => response.text())
            .then((data) => setUnitTypes(JSON.parse(data)))
    }, [props.account.customerId])

    const valid = checkMigrationValid(props.mappings)

    if (procedures.status === 'loading')
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )

    return (
        <div className="ProcedureSection">
            <h2>Migrating {props.template.name}</h2>
            <div className="row">
                <div className="column">
                    <p>
                        Name Flow
                        <sub>Name must be unique to the procedure</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <TextField
                            onChange={(event) => {
                                props.setMappings(valid, {
                                    name: event.target.value,
                                    procedure: props.mappings.procedure,
                                    subjectUnit: props.mappings.subjectUnit,
                                    procedureName: props.mappings.procedureName,
                                    migrateSince: props.mappings.migrateSince,
                                    taskTraining: props.mappings.taskTraining
                                })
                            }}
                            value={props.mappings.name}
                            variant="outlined"
                        />
                    </FormControl>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>
                        Select Procedure:
                        <sub>This is where the template will be migrated to</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <InputLabel>Target Procedure</InputLabel>
                        <Select
                            label="Target Procedure"
                            onChange={(event) => {
                                props.setMappings(valid, {
                                    name: props.mappings.name,
                                    procedure: parseInt(event.target.value + ''),
                                    subjectUnit: props.mappings.subjectUnit,
                                    procedureName: props.mappings.procedureName,
                                    migrateSince: props.mappings.migrateSince,
                                    taskTraining: props.mappings.taskTraining
                                })
                            }}
                            value={props.mappings.procedure}
                        >
                            <MenuItem value={0}>
                                <b>+ Add New</b>
                            </MenuItem>
                            {procedures.procedures.map((procedure) => {
                                return <MenuItem value={procedure.id}>{procedure.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {props.mappings.procedure === 0 ? (
                        <FormControl fullWidth>
                            <TextField
                                label="Procedure Name"
                                value={props.mappings.procedureName || ''}
                                onChange={(event) => {
                                    props.setMappings(valid, {
                                        name: props.mappings.name,
                                        procedure: props.mappings.procedure,
                                        subjectUnit: props.mappings.subjectUnit,
                                        procedureName: event?.target.value,
                                        migrateSince: props.mappings.migrateSince,
                                        taskTraining: props.mappings.taskTraining
                                    })
                                }}
                            />
                        </FormControl>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>
                        Select Subject Type:
                        <sub>Can be left blank - the subject is used to localise the task in Zaptic</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <InputLabel>Subject Type</InputLabel>
                        <Select
                            label="Subject Type"
                            onChange={(event) => {
                                props.setMappings(valid, {
                                    name: props.mappings.name,
                                    procedure: props.mappings.procedure,
                                    subjectUnit: parseInt(event.target.value + ''),
                                    procedureName: props.mappings.procedureName,
                                    migrateSince: props.mappings.migrateSince,
                                    taskTraining: props.mappings.taskTraining
                                })
                            }}
                            value={props.mappings.subjectUnit}
                        >
                            <MenuItem>None</MenuItem>
                            {unitTypes.unitTypes.map((unitType) => {
                                return <MenuItem value={unitType.id}>{unitType.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>
                        Task or training
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                    <ToggleButtonGroup
                        color="primary"
                        value={props.mappings.taskTraining}
                        exclusive
                        onChange={(e, value) => {
                            props.setMappings(valid, {
                                name: props.mappings.name,
                                procedure: props.mappings.procedure,
                                subjectUnit: props.mappings.subjectUnit,
                                procedureName: props.mappings.procedureName,
                                migrateSince: props.mappings.migrateSince,
                                taskTraining: value
                            })
                        }}
                        aria-label="Platform"
                        >
                        <ToggleButton value="task">Task</ToggleButton>
                        <ToggleButton value="training">Training</ToggleButton>
                    </ToggleButtonGroup>
                    </FormControl>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>
                        Migrate Data?
                        <sub>
                            Archived data is stored and does not need to be copied. This tool should only be used to
                            migrate operationally relevant jobs.
                        </sub>
                    </p>
                </div>
                <div className="column">
                    <Checkbox
                        value={!!props.mappings.migrateSince}
                        checked={!!props.mappings.migrateSince}
                        onChange={(e) => {
                            const date = new Date()
                            date.setMonth(date.getMonth() - 12)
                            if (e.target.checked)
                                props.setMappings(valid, {
                                    name: props.mappings.name,
                                    procedure: props.mappings.procedure,
                                    subjectUnit: props.mappings.subjectUnit,
                                    procedureName: props.mappings.procedureName,
                                    migrateSince: date,
                                    taskTraining: props.mappings.taskTraining
                                })
                            else
                                props.setMappings(valid, {
                                    name: props.mappings.name,
                                    procedure: props.mappings.procedure,
                                    subjectUnit: props.mappings.subjectUnit,
                                    procedureName: props.mappings.procedureName,
                                    migrateSince: null,
                                    taskTraining: props.mappings.taskTraining
                                })
                        }}
                    />
                    {!!props.mappings.migrateSince ? (
                        <LocalizationProvider adapterLocale={'en-GB'} dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth>
                                <DatePicker
                                    label="Jobs Started Since"
                                    value={dayjs(props.mappings.migrateSince)}
                                    onChange={(e) => {
                                        props.setMappings(valid, {
                                            name: props.mappings.name,
                                            procedure: props.mappings.procedure,
                                            subjectUnit: props.mappings.subjectUnit,
                                            procedureName: props.mappings.procedureName,
                                            migrateSince: e ? e.toDate() : null,
                                            taskTraining: props.mappings.taskTraining
                                        })
                                    }}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div>
                <a onClick={(e) => {
                    props.applyToAll(props.mappings)
                }}><sub className='applyAll'>Apply to all</sub></a>
            </div>
        </div>
    )
}
type CopyOverProps = {
    template: MappedTemplates,
    connection: ExternalConnection,
    existingWorkflow? : number,
    markComplete : () => void
}
export function CopyOver(props: CopyOverProps) {
    const [newWorkflow, setNewWorkflow] = useState<null | any>(null)
    const [progress, setProgress] = useState<number>(0)
    const [total, setTotal] = useState<number | null>(null)
    const [alreadyExists, setAlreadyExists] = useState<number>(0)
    const [error, setError] = useState<number>(0)
    const [success, setSuccess] = useState<number>(0)

    useEffect(() => {
        const getProcedure = async () => {
            if (props.template.mappings.procedure !== 0) return props.template

            // If not, create the procedure
            const newProcedure = (await fetch(
                '/createProcedure?customerId=' +
                    props.template.instance.customerId +
                    '&sourceCustomerId=' +
                    props.template.instance.customerId,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: props.template.mappings.procedureName,
                    }),
                }
            ).then((response) => response.json())) as any
            const updatedProcedure = { ...props.template }
            updatedProcedure.mappings.procedure = newProcedure.response.id
            return updatedProcedure
        }

        getProcedure().then((instance) => {
            const flow = {
                name: props.template.mappings.name,
                flow: templateToFlow(props.template.template, props.template.mappings.subjectUnit,props.template.instance, props.connection),
                type: props.template.mappings.taskTraining,
                adHoc: true,
                isDeleted: false,
                numberOfVersions: 1,
                requiresSignature: false,
                displaysTextContentInReports: true,
                trainingRecommendations: [],
                reportStatuses: {
                    statuses: [
                        {
                            name: 'In Progress',
                        },
                        {
                            name: 'Closed',
                        },
                    ],
                    defaultIndex: 0,
                },
                procedure: {
                    id: props.template.mappings.procedure,
                    name: '',
                    description: null,
                },
                tags: [],
                elementTags: [],
            } as AddEndpoint['Body'] & {
                procedure: {
                    id: number
                    name: string
                }
            } & {
                id ?: number
            }
            if (props.existingWorkflow) {
                flow.id = props.existingWorkflow;
            }

            let flowToSend = flow; 

            if (props.template.mappings.migrateSince) flowToSend = makeAllOption(JSON.parse(JSON.stringify(flow)))

            let endPoint; 
            if (props.existingWorkflow)
                endPoint = "updateFlow"
            else 
                endPoint = "copyFlow"

            fetch(
                `/${endPoint}?customerId=` + instance.instance.customerId,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(flowToSend),
                }
            )
                .then((response) => response.text())
                .then((data) => {
                    setNewWorkflow(JSON.parse(data))
                    return JSON.parse(data)
                })
                .then(async (workflow) => {
                    if (props.template.mappings.migrateSince === null)
                        return props.markComplete(); // If no report migration needed then we are done
                    const answers = await getCompletedJobs(props.connection, [props.template.template.id as string], new Date(props.template.mappings.migrateSince).getTime() / 1000)
                    setTotal(answers.length)
                    // Declaring locally as cannot incriment state in an effect
                    let successVar = 0
                    let errorVar = 0
                    let alreadyExistVar = 0
                    const userId = workflow.response.workflow.lastModifiedBy || 1
                    for (var i = 0; i < answers.length; i++) {
                        try {
                            const answer = answers[i]
                            const report = await createReport(
                                props.template.instance,
                                props.connection,
                                props.template.mappings.name,
                                answer,
                                workflow.response as AddWorkflowOutput,
                                props.template.mappings.procedure || 1,
                                userId
                            )

                            let response
                            if (report)
                                response = await fetch('/addReport?customerId=' + instance.instance.customerId, {
                                    method: 'POST',
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(report),
                                }).then((response) => {
                                    return response.json()
                                })
                            setProgress(i)
                            if (!response || response.status === 'fail')
                                if (response?.response === 'already-exists') setAlreadyExists(alreadyExistVar++)
                                else setError(errorVar++)
                            else setSuccess(successVar++)
                        } catch (e) {
                            console.log('Upload Error', e)
                            setProgress(i)
                            setError(errorVar++)
                        }
                    }

                    let flowToSend = flow as DuplicateFlow['workflow']

                    flowToSend.id = workflow.response.workflow.id

                    //Updating flow to now keep mandatory fields
                    await fetch('/updateFlow?customerId=' + instance.instance.customerId, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(flowToSend),
                    })

                    props.markComplete()
                })
        })
    }, [props.template.instance])

    if (!newWorkflow) return <div className="pulseLoader"></div>

    if (newWorkflow.response.message == 'Invalid flow') {
        return (
            <li>
                <p>
                    ⚠️ {props.template.mappings.name} Migration Failed
                    <sub>
                        {newWorkflow.response.errors.find((err: any) => err.type === 'DuplicateName')
                            ? 'Duplicate Name In procedure '
                            : 'Error while copying: ' +
                              newWorkflow.response.errors.map((err: any) => err.type).jon(',')}
                    </sub>
                </p>
            </li>
        )
    }
    if (props.template.mappings.migrateSince && total === null)
        return (
            <li>
                <p>
                    ☁ Migrating jobs for {props.template.mappings.name}
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary' }}
                        >{`Getting Jobs From Parsable`}</Typography>
                    </Box>
                </p>
            </li>
        )
    if (props.template.mappings.migrateSince && total && progress + 1 < total)
        return (
            <>
                <li>
                    <p>
                        ☁ Migrating jobs for {props.template.mappings.name}
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={((progress + 1) / total) * 100} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>{`${Math.round(
                                ((progress + 1) / total) * 100
                            )}%`}</Typography>
                        </Box>
                    </p>
                    <sub>
                        Migrating {progress + 1} / {total}
                    </sub>
                    {success > 0 ? (
                        <sub>
                            <b className="added">{success} jobs copied</b>
                        </sub>
                    ) : (
                        ''
                    )}
                    {error > 0 ? (
                        <sub>
                            <b className="missing">{error} jobs had errors</b>
                        </sub>
                    ) : (
                        ''
                    )}
                    {alreadyExists > 0 ? (
                        <sub>
                            <b>{alreadyExists} jobs already migrated</b>
                        </sub>
                    ) : (
                        ''
                    )}
                </li>
            </>
        )

    return (
        <li>
            <p>
                ✅ {props.template.mappings.name}
                <sub>
                    Flow copied migrated! Now you can add schedules, statuses, filters and other instance level features
                    by pressing the preview icon
                </sub>
                {props.template.mappings.procedure ? (
                    <a
                        rel="noreferrer"
                        href={
                            'https://' +
                            props.template.instance.serverURL +
                            '/procedures/' +
                            props.template.mappings.procedure +
                            '/tasks/workflows/' +
                            newWorkflow.response.workflow.id +
                            '/edit'
                        }
                        target="_blank"
                    >
                        <button>
                            <EyeIcon />
                        </button>
                    </a>
                ) : (
                    ''
                )}
            </p>
            {success > 0 ? (
                <sub>
                    <b className="added">{success} jobs copied</b>
                </sub>
            ) : (
                ''
            )}
            {error > 0 ? (
                <sub>
                    <b className="missing">{error} jobs had errors</b>
                </sub>
            ) : (
                ''
            )}
            {alreadyExists > 0 ? (
                <sub>
                    <b>{alreadyExists} jobs already migrated</b>
                </sub>
            ) : (
                ''
            )}
        </li>
    )
}
